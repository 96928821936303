import Home from "./Home.jsx";
import CalidadPrincipal from "./Proyectos/Calidad/CalidadPrincipal.jsx";
import InsetarIndicador from "./Proyectos/Calidad/InsertarIndicador/InsetarIndicador.jsx";
import Procesos from "./Proyectos/Calidad/Procesos/Procesos.jsx";
import Cartera from "./Proyectos/GerenciaVisual/Cartera/Cartera.jsx";
import GerencialVisualPrincipal from "./Proyectos/GerenciaVisual/GerencialVisualPrincipal.jsx";
import Indicadores from "./Proyectos/GerenciaVisual/Indicadores/Indicadores.jsx";
import LineaTiempoIndicador from "./Proyectos/GerenciaVisual/Indicadores/VistaEmpresa/CartaIndicador/LineaTiempoIndicador/LineaTiempoIndicador.jsx";
import ResultadoIndicador from "./Proyectos/GerenciaVisual/Indicadores/VistaEmpresa/CartaIndicador/ResultadoIndicador/ResultadoIndicador.jsx";
import RecaudoVendedor from "./Proyectos/GerenciaVisual/RecaudoVendedor/RecaudoVendedor.jsx";

import Presupuesto from "./Proyectos/GerenciaVisual/Presupuesto/Presupuesto.jsx";
import ProfundidadAlPortafolio from "./Proyectos/GerenciaVisual/ProfundidadAlPortafolio/ProfundidadAlPortafolio.jsx";
import { Ventas } from "./Proyectos/GerenciaVisual/Ventas/Ventas.jsx";
import VisualEmpresa from "./Proyectos/GerenciaVisual/VisualEmpresa/VisualEmpresa.jsx";
import VentasCartera from "./Proyectos/GestionComercial/Cartera/Cartera.jsx";
import GestionComercial from "./Proyectos/GestionComercial/GestionComercial.jsx";
import { VentasComercial } from "./Proyectos/GestionComercial/Ventas/VentasComercial.jsx";
import GestionVisualEmpresa from "./Proyectos/GestionComercial/VisualEmpresa/GestionVisualEmpresa.jsx";
import Contenedores from "./Proyectos/Importaciones/Contenedores/Contenedores.jsx";
import ImportacionesPrincipal from "./Proyectos/Importaciones/ImportacionesPrincipal.jsx";
import Parametros from "./Proyectos/Importaciones/Parametros/Parametros.jsx";
import Adminitrador from "./components/adminitrador/Adminitrador.jsx";
import PermisoRuta from "./PermisoRuta.jsx";
import PresupuestoComercial from "./Proyectos/GestionComercial/Presupuesto/PresupuestoComercial.jsx";
import SeguimientoAsesor from "./Proyectos/GerenciaVisual/SeguimientoAsesor/SeguimientoAsesor.jsx";
import Firmas from "./Proyectos/Configuraciones/Firmas/Firmas.jsx";
import { Tecnica } from "./Proyectos/Tecnica/Tecnica.jsx";
import { MenuFinanciera } from "./Proyectos/Financiera/MenuFinanciera.jsx";
import { PrincipalPyG } from "./Proyectos/Financiera/PyG/PrincipalPyG.jsx";
import FinancieraActPas from "./Proyectos/Financiera/ActivosPasivos/FinancieraActPas.jsx";



// import CreacionRequerimientos from "./Proyectos/Requerimientos/CreacionRequerimientos.jsx";
// import CreacionRequerimientos from "./Proyectos/Tickets/CreacionRequerimientos.jsx";



import Tickets from "./components/adminitrador/Tickets/Tickets.jsx";
import PagosWompi from "./Proyectos/Financiera/PagosWompi/PagosWompi.jsx";
import CreacionTickets from "./Proyectos/Tickets/CreacionTickets.jsx";

const routes = [
    { path: '/Farmanet', element: <Home /> },
    { path: 'GerenciaVisual', element: <GerencialVisualPrincipal /> },
    {
        path: "GerenciaVisual/ProfundidadAlPortafolio",
        element: <PermisoRuta rolesPermitidos={[1, 3, 50]} element={<ProfundidadAlPortafolio />} />
    },
    {
        path: "GerenciaVisual/VisualEmpresa",
        element: <PermisoRuta rolesPermitidos={[1, 1]} element={<VisualEmpresa />} />
    },
    {
        path: "GerenciaVisual/SeguimientoAsesor",
        element: <PermisoRuta rolesPermitidos={[1, 1]} element={<SeguimientoAsesor />} />
    },
    {
        path: "GerenciaVisual/Presupuesto",
        element: <PermisoRuta rolesPermitidos={[1, 1, 3]} element={<Presupuesto />} />
    },
    {
        path: "GerenciaVisual/Ventas",
        element: <PermisoRuta rolesPermitidos={[1, 1]} element={<Ventas />} />
    },
    {
        path: "GerenciaVisual/Cartera",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<Cartera />} />
    },
    {
        path: "GerenciaVisual/RecaudoPorVendedor",
        element: <PermisoRuta rolesPermitidos={[1, 2]} element={<RecaudoVendedor />} />
    },
    {
        path: "GerenciaVisual/Indicadores",
        element: <Indicadores />
    },
    {
        path: "GerenciaVisual/Indicadores/LineaTiempoIndicador/:indProEmpSec/:tipo/:fecha/:mesData",
        element: <LineaTiempoIndicador />
    },
    {
        path: "GerenciaVisual/Indicadores/LineaTiempoIndicador/:indProEmpSec/:tipo/ResultadoIndicador/:indicador/:criterio",
        element: <ResultadoIndicador />
    },
    {
        path: "*", element: <NotFound />
    },
    // **************************************************************************************
    { path: '/Farmanet/FirmaDigital', element: <Firmas /> },
    // IMPORTACIONES
    { path: "Importaciones", element: <ImportacionesPrincipal /> },
    {
        path: "ParametrosImportaciones",
        element: <PermisoRuta rolesPermitidos={[1, 1]} element={<Parametros />} />
    },
    {
        path: "ContenedoresImportaciones",
        element: <PermisoRuta rolesPermitidos={[1, 1]} element={<Contenedores />} />
    },
    { path: "Calidad", element: <CalidadPrincipal /> },
    { path: "ParametrosCalidad", element: <Procesos /> },
    // { path: "InsetarIndicadorCalidad", element: <InsetarIndicador /> },

    // { path: "InsetarIndicadorCalidad", element: <InsetarIndicador /> },

    {
        path: "InsetarIndicadorCalidad",
        element: <PermisoRuta rolesPermitidos={[1]} element={<InsetarIndicador />} />
    },

    // GESTION COMERCIAL
    { path: "GestionComercial", element: <GestionComercial /> },
    { path: "GestionComercial/VisualEmpresa", element: <GestionVisualEmpresa /> },
    { path: "GestionComercial/Ventas", element: <VentasComercial /> },
    { path: "GestionComercial/Cartera", element: <VentasCartera /> },
    { path: "GestionComercial/PresupuestoComercial", element: <PresupuestoComercial /> },
    // ***************************************************************************
    { path: "Tecnica", element: <Tecnica /> },
    // Financiera
    { path: "Financiera", element: <MenuFinanciera /> },
    {
        path: "Financiera/Pyg",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<PrincipalPyG />} />
    },
    {
        path: "Financiera/ActivoPasivos",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<FinancieraActPas />} />
    },
    {
        path: "Financiera/PagosWompi",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<PagosWompi />} />
    },

    // Creación de requerimientos
    { path: "/Farmanet/CreacionTickets", element: <CreacionTickets /> },
    // { path: "Adminitrador", element: <Adminitrador /> },
    {
        path: "Administrador",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<Adminitrador />} />
    },
    {
        path: "Administrador/Tickets",
        element: <PermisoRuta rolesPermitidos={[1, 33]} element={<Tickets />} />
    },
];

function NotFound() {
    return <div style={{ marginTop: "2rem" }}>Ha llegado a una página que no existe</div>;
}

export default routes;
